import React, { useState, useRef } from "react";
import axios from "axios";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

//import "../assets/css/custom.css";

const url = "https://prageethperera-001-site1.etempurl.com/api/login";

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const toast = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleLogin = (event) => {
    event.preventDefault();

    axios
      .post(url, formData)
      .then((res) => {
        const token = res.data.jwt;
        localStorage.setItem("token", token);

        window.location.href = "/";
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            "Invalid username, password or unauthorized role. Please try again.",
          life: 3000,
        });
      });
  };

  return (
    <div style={{ background: "#fff" }}>
      <Toast ref={toast} position="bottom-right" />
      <div
        className=""
        style={{
          minHeight: "calc(100vh - 419px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "20px",
        }}
      >
        <div className="grid w-full">
          <div className="col flex align-items-center justify-content-center mb-5">
            <div style={{ width: "300px" }}>
              <div className="flex justify-content-center"></div>
              <h3 className="mt-4">Geeth Curtains - Admin</h3>
              <label>Please Login to continue</label>
              <form
                className="flex flex-column gap-3 mt-4"
                onSubmit={handleLogin}
              >
                <div className="flex flex-column">
                  <label>Username *</label>
                  <InputText
                    id="username"
                    name="username"
                    placeholder="Username"
                    autoComplete="off"
                    type="text"
                    value={formData.username}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="flex flex-column">
                  <div>Password *</div>
                  <InputText
                    id="passsword"
                    name="password"
                    type="password"
                    placeholder="Password"
                    autoComplete="off"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="login-submit-btn">
                  <Button
                    className="p-button-outlined btn-outlined"
                    type="submit"
                    label="Login"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
