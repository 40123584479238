import { useLanguage } from "language-maps/language-context";
import { cart } from "language-maps/cart-language-map";
import { contactUs } from "language-maps/contact-us-language-map";
import { gallery } from "language-maps/gallery-language-map";
import { home } from "language-maps/home-language-map";
import { item } from "language-maps/Item-language-map";
import { meetUS } from "language-maps/meet-is-language-map";

export function useTranslation() {

    const { language}  = useLanguage();

    const maps = {
        ...cart,
        ...contactUs,
        ...gallery,
        ...home,
        ...item,
        ...meetUS

    };
  
    const t = (text) => {
      return maps[text] && maps[text][language] ? maps[text][language] : text;
    };
  
    return { t };
}