import React, { useEffect, useRef } from "react";

export default function SnowOverlay() {
  const containerRef = useRef(null);

  useEffect(() => {
    const snowContainer = containerRef.current;
    const flakeChar = "❄";
    const minSize = 10;
    const maxSize = 30;
    const creationInterval = 500;
    const fallSpeedRange = [0.5, 1.5];
    const driftXRange = [0.1, 0.5];
    const bottomBuffer = 5;
    const removalDelay = 15000;
    let flakes = [];
    let wind = 0;
    let targetWind = 0;
    const windSmooth = 0.05;
    let animationFrame;

    function onMouseMove(e) {
      const centerX = window.innerWidth / 2;
      const relativeX = e.clientX - centerX;
      targetWind = relativeX * 0.001;
    }

    document.addEventListener("mousemove", onMouseMove);

    const intervalId = setInterval(createFlake, creationInterval);

    function animate() {
      wind += (targetWind - wind) * windSmooth;
      for (let i = 0; i < flakes.length; i++) {
        const f = flakes[i];
        if (!f.settled) {
          f.x += f.driftX + wind;
          f.y += f.speedY;
          if (f.y + f.size >= window.innerHeight - bottomBuffer) {
            f.y = window.innerHeight - f.size - bottomBuffer;
            f.settled = true;
            f.driftX = 0;
            setTimeout(() => {
              if (f.el.parentNode) f.el.parentNode.removeChild(f.el);
              flakes = flakes.filter((fl) => fl !== f);
            }, removalDelay);
          }
          f.el.style.transform = `translate(${f.x}px, ${f.y}px)`;
        }
      }
      animationFrame = requestAnimationFrame(animate);
    }

    animate();

    function createFlake() {
      const size =
        Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize;
      const el = document.createElement("div");
      el.className = "snowflake";
      el.textContent = flakeChar;
      el.style.fontSize = size + "px";
      const startX = Math.random() * window.innerWidth;
      const startY = -size;
      const speedY =
        Math.random() * (fallSpeedRange[1] - fallSpeedRange[0]) +
        fallSpeedRange[0];
      const driftX =
        (Math.random() < 0.5 ? 1 : -1) *
        (Math.random() * (driftXRange[1] - driftXRange[0]) + driftXRange[0]);
      el.style.transform = `translate(${startX}px, ${startY}px)`;
      snowContainer.appendChild(el);
      flakes.push({
        el,
        x: startX,
        y: startY,
        size,
        speedY,
        driftX,
        settled: false,
      });
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      clearInterval(intervalId);
      cancelAnimationFrame(animationFrame);
      flakes.forEach((f) => {
        if (f.el.parentNode) f.el.parentNode.removeChild(f.el);
      });
      flakes = [];
    };
  }, []);

  return (
    <>
      <div
        className="snow-overlay"
        ref={containerRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          pointerEvents: "none",
          width: "100%",
          height: "100%",
          zIndex: 9999,
          overflow: "hidden",
        }}
      />
      <style>
        {`
          .snowflake {
            position: absolute;
            color: #CCE5FF;
            text-shadow: 0 0 4px #EAF2FB;
            font-size: 0.8rem;
            opacity: 0.9;
            user-select: none;
            pointer-events: none;
            will-change: transform;
          }
        `}
      </style>
    </>
  );
}
