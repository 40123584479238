import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useTranslation } from "language-maps/use-translation"; 

import "../../assets/css/contact-us.css";

const MeetUs = () => {
  
  const { t } = useTranslation();

  const branches = [
    {
      name: t("Ragama"),
      phone: "071 8021976",
      address: t("No 72, Thewatha Road, Ragama"),
      directionsLink: "#",
    },
    {
      name: t("Walpola"),
      phone: "071 8021976",
      address: t("No 16, Walpola Ragama"),
      directionsLink: "#",
    },
    {
      name: t("Welimada"),
      phone: "071 8021976",
      address: t("9th Post Nawela, Mirahawaththa, Welimada"),
      directionsLink: "#",
    },
    {
      name: t("Kurunegala"),
      phone: "071 8021976",
      address: t("Danbokka Junction, Colombo Rd, Kurunegala"),
      directionsLink: "#",
    },
  ];

  const renderCard = (branch) => {
    return (
      <div className="col-12 lg:col-3 p-2 cursor-pointer" key={branch.name}>
        <Card className="contact-us-card">
          <div className="flex flex-column justify-content-between contact-us-card-content">
            <div className="flex flex-column lg:gap-5 gap-4">
              <div className="cormorant-garamond text-2xl font-semibold text-center">
                {branch.name}
              </div>
              <div className="flex flex-column align-items-center gap-2">
                <i className="pi pi-phone text-color desktop-specs"></i>
                <div className="text-sm font-semibold">{branch.phone}</div>
              </div>
              <div className="flex flex-column align-items-center gap-2">
                <i className="pi pi-map-marker text-color desktop-specs"></i>
                <div className="text-sm font-semibold text-center address">
                  {branch.address}
                </div>
              </div>
            </div>
            <Button
              label={t("Get Directions")}
              icon="pi pi-arrow-right"
              className="p-button-outlined p-button-sm btn-outlined"
              onClick={() => window.open(branch.directionsLink, "_blank")}
            />
          </div>
        </Card>
      </div>
    );
  };

  return (
    <div className="pt-6 pb-6">
      <div className="content-wrapper mb-0">
        <h1 className="text-center">{t("Meet Us")}</h1>
        <div className="flex justify-content-center align-items-center mt-2 mb-2">
          <span className="icon-border pi pi-phone m-2"></span>
          <span className="text-color cormorant-garamond lg:text-5xl text-2xl m-2">
            {t("Hotline")}
          </span>
          <span className="text-color cormorant-garamond font-semibold lg:text-5xl text-2xl m-2">
            +94 71 802 1976
          </span>
        </div>
        <div className="flex justify-content-center">
          <p className="page-context">
            {t("With our strategically located branches spread across the island we are here to ensure that our service is easily accessible to you wherever you are.")}
          </p>
        </div>
        <div className="grid justify-content-center mt-4">
          {branches.map((branch) => renderCard(branch))}
        </div>
      </div>
    </div>
  );
};

export default MeetUs;