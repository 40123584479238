import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import "../../assets/css/home.css";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { numberWithCommas } from "CustomMath";

import { Button } from "primereact/button";

const Latest = () => {
  const [products, setProducts] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    loadData();
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const loadData = async () => {
    let data = [];
    const querySnapshot = await getDocs(collection(db, "Designs"));
    querySnapshot.forEach((doc) => {
      if (doc.exists && doc.data().Status) {
        const record = {
          id: doc.id,
          code: doc.id,
          name: doc.data().Name,
          description: doc.data().Description,
          image: doc.data().Image,
          price: doc.data().Price,
        };
        data.push(record);
      }
    });
    data = data.slice(Math.max(data.length - 8, 1));
    setProducts(data);
  };

  const productTemplate = (product) => {
    return (
      <Link to={`/item/${product.code}`} className="latest-link">
        <img
          className="latest-image"
          src={`https://firebasestorage.googleapis.com/v0/b/geethcuratins.appspot.com/o/${product.image}?alt=media&token=debc5e8a-2c21-45a3-b0d6-50de7f8166d7`}
          alt="not found"
        />
        <div className="flex flex-column align-items-center lg:mb-3 gap-1">
          <span className="w-full text-center cormorant-garamond text-secondary text-2xl ellipsis font-semibold">
            {product.name}
          </span>
          <span className="text-primary text-center ellipsis font-medium">
            {numberWithCommas(product.price)} LKR
          </span>
        </div>
      </Link>
    );
  };

  const renderProducts = () => {
    if (isMobile) {
      const visibleProducts = products.slice(0, 4);
      const chunks = chunkArray(visibleProducts, 2);
      return chunks.map((chunk, index) => (
        <div key={index} className="flex justify-center lg:gap-4 mb-4">
          {chunk.map((product) => productTemplate(product))}
        </div>
      ));
    } else {
      return (
        <Carousel
          value={products}
          numVisible={3}
          numScroll={2}
          circular
          autoplayInterval={5000}
          itemTemplate={productTemplate}
          className="cursor-pointer"
          showNavigators={false}
        />
      );
    }
  };

  const chunkArray = (array, chunkSize) => {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      results.push(array.slice(i, i + chunkSize));
    }
    return results;
  };

  return (
    <div className="p-4 lg:my-4">
      <div className="flex flex-column">
        <hr />
        <div className="mb-4 flex flex-column align-items-center gap-3">
          <div
            className="bg-white lg:px-8 px-4 lg:text-xl font-medium text-color"
            style={{ marginTop: "-1.5rem" }}
          >
            NEW COLLECTION
          </div>
          <div className="text-4xl lg:text-7xl font-medium text-color mb-4 cormorant-garamond">
            Our Latest Arrivals
          </div>
        </div>
        <div className="content-wrapper">{renderProducts()}</div>
      </div>
      <div className="flex justify-content-center mt-4">
        <Button
          label="VIEW ALL"
          className="p-button-outlined"
          onClick={() => navigate("/gallery")}
          text
        />
      </div>
    </div>
  );
};

export default Latest;
