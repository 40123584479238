import React, { useCallback, useEffect, useState, useRef} from "react";
import { useNavigate, useParams } from "react-router-dom";
 
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
 
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
 
import { store } from "../store";
 
import { numberWithCommas } from "../CustomMath";
 
import "assets/css/item.css";
import { useTranslation } from "language-maps/use-translation"; 
 
const defaultState = {
  activeIndex: 0,
  selectedDesign: {},
  image: "",
  type: "Door",
  fabricPrice: 0,
  price: 850,
  width: 36,
  height: 100,
  pleats: 9,
  cost: 0,
  quantity: 1,
  total: 0,
  isPole: true,
  fabricCost: 0,
};

const types = [
  { name: "Door", value: 36 },
  { name: "Single Window", value: 24 },
  { name: "Double Window", value: 48 },
  { name: "Triple Window", value: 72 },
  { name: "Quadruple Window", value: 96 },
  { name: "Other", value: 120 },
];

const Item = () => {
  const [state, setState] = useState({ ...defaultState });
  const { id } = useParams();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation();

  const loadData = useCallback(async () => {
    const docRef = doc(db, "Designs", id);
    const docSnap = await getDoc(docRef);
 
    if (docSnap.exists()) {
      const data = docSnap.data();
      const currentState = {
        ...state,
        selectedDesign: data,
        image: data.Image,
        price: data.Price,
      };
      calculate(currentState);
    }
  }, [id]);
 
  useEffect(() => {
    loadData();
  }, [loadData]);
 
  const onTypeChange = (value) => {
    const type = types.find((t) => t.name === value);
    if (!type) return;
    const temp = {
      ...state,
      type: type.name,
      width: type.value,
    };
 
    calculate(temp, true);
  };
 
  const onPoleChange = (e) => {
    const temp = { ...state, isPole: e.value };
    calculate(temp, false);
  };
 
  const onChange = (name, value, getPleats = true) => {
    const temp = { ...state, [name]: value };
    calculate(temp, getPleats);
  };
 
  const getRecommended = (currentState) => {
    let pleats = Math.round((currentState?.width ?? state.width) / 3.92);
 
    if (
      pleats % 2 !== 0 &&
      !(
        (currentState?.type ?? state.type) === "Door" ||
        (currentState?.type ?? state.type) === "Single Window"
      )
    ) {
      pleats += 1;
    }
 
    return pleats;
  };
 
  const calculate = (currentState, getPleats = true) => {
    const pleats = getPleats
      ? getRecommended(currentState)
      : currentState.pleats;
 
    const price = currentState.price;
    let fabricPrice = price;
 
    if (currentState.height > 102) fabricPrice += price * 0.1;
    if (currentState.height < 48) fabricPrice = price / 2;
    const fabricCost =
      currentState.quantity *
      fabricPrice *
      (Math.round(((pleats * 14) / 39) * 100) / 100);
    const barCost =
      (currentState.width < 48 ? 5 : currentState.width / 12 + 1) * 300;
    let cost =
      (barCost +
        ((pleats * 14) / 39) * (fabricPrice + 65) +
        pleats * 60 +
        pleats * 2 * 46) *
      (1.03 * 1.18 * 1.082);
     
    if (!currentState.isPole) cost -= barCost * 1.25;
    const total = +cost * currentState.quantity;
 
    setState(() => ({
      ...currentState,
      pleats,
      fabricPrice,
      fabricCost,
      cost,
      total,
    }));
  };
 
  const addToCart = () => {
    store.dispatch({
      type: "itemAdded",
      payload: state,
    });
 
    setShowDialog(true);
  };
 
  const reset = () => {
    const temp = {
      ...defaultState,
      selectedDesign: state.selectedDesign,
      image: state.image,
      price: state.price,
    };
 
    setState(temp);
    calculate(temp);
    setShowDialog(false);
  };
 
  const typeRef = useRef(null);
 
  const scrollToType = () => {
    if (typeRef.current) {
      typeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
 
  const hightRef = useRef(null);
 
  const scrollTohight = () => {
    if (hightRef.current) {
      hightRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
 
  const pleatsRef = useRef(null);
 
  const scrollTopleats = () => {
    if (pleatsRef.current) {
      pleatsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
 
  const polesRef = useRef(null);
 
  const scrollTopoles = () => {
    if (polesRef.current) {
      polesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
 
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Dialog
        header="Item Added"
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        closable={false}
        style={{ width: "500px", maxWidth: "90%" }}
        draggable={false}
      >
        <p>
          {t("Selection added to the cart successfully. Do you want to continue")}
          {t("shopping or go to the cart?")}
        </p>
        <div className="gap-2 flex">
          <button onClick={() => reset()} className="dialog-button">
            {t('Continue Shopping')}
          </button>
          <button onClick={() => navigate("/cart")} className="dialog-button">
            {t('Go to Cart')}
          </button>
        </div>
      </Dialog>
      <div className="content-wrapper">
        <div className="grid">
          <div className="col-12 lg:col-5 lg:pr-3">
            <Button
              label="Back to store"
              icon="pi pi-angle-left"
              className="px-0 py-3"
              severity="secondary"
              onClick={() => navigate("/gallery")}
              text
            />
            <div>
              <h4>{state.selectedDesign?.Name}</h4>
              <label>{state.selectedDesign?.Description}</label>
            </div>
            <div className="flex justify-content-between mt-3">
              <label>LKR {state.selectedDesign?.Price}.00 per meter</label>
              <label>Item Code :{state.selectedDesign?.Code}</label>
            </div>
            <img
              className="item-image mt-1"
              src={`https://firebasestorage.googleapis.com/v0/b/geethcuratins.appspot.com/o/${state.image}?alt=media&token=debc5e8a-2c21-45a3-b0d6-50de7f8166d7`}
              alt="Not Found"
            />
          </div>
          <div className="col-12 lg:col-7 lg:pl-3">
            <h1 className="text-4xl lg:text-6xl line-height-1 mt-5 lg:mt-7">
              {t('Customize Selected Design')}
            </h1>
            <div className="flex align-items-center mt-3">
              {t('Type')}
              <Button
                className="p-button-help"
                icon="pi pi-info-circle"
                label={t("View Description")}
                onClick={scrollToType}
              />
            </div>
            <div className="design-types row ">
              <div
                className="col-4 lg:col-2 cursor-pointer"
                onClick={() => onTypeChange("Door")}                
              > 
               <div className={`item-frame ${state.type === "Door" ? "active-frame" : ""}`}>
                  <img
                    className="img-fluid img-item"
                    src={require("../assets/img/gallery.img/door.jpg")}
                    alt="Door"
                  />
                  <div className="text-center item-name">
                    {t("Door")}
                  </div>
                </div>      
              </div>
              <div
                className="col-4 lg:col-2 cursor-pointer"
                onClick={() => onTypeChange("Single Window")}                
              > 
               <div className={`item-frame ${state.type === "Single Window" ? "active-frame" : ""}`}>
                  <img
                    className="img-fluid img-item"
                    src={require("../assets/img/gallery.img/single-window.jpg")}
                    alt="Single Window"
                  />
                  <div className="text-center item-name">
                    {t("Single Window")}
                  </div>
                </div>
               </div>  
               <div
                className="col-4 lg:col-2 cursor-pointer"
                onClick={() => onTypeChange("Double Window")}                
              > 
               <div className={`item-frame ${state.type === "Double Window" ? "active-frame" : ""}`}>
                  <img
                    className="img-fluid img-item"
                    src={require("../assets/img/gallery.img/double-window.jpg")}
                    alt="Double Window"
                  />
                  <div className="text-center item-name">
                    {t("Double Window")}
                  </div>
                </div>
               </div>  
              <div
                className="col-4 lg:col-2 cursor-pointer"
                onClick={() => onTypeChange("Triple Window")}                
              > 
               <div className={`item-frame ${state.type === "Triple Window" ? "active-frame" : ""}`}>
                  <img
                    className="img-fluid img-item"
                    src={require("../assets/img/gallery.img/triple-window.jpg")}
                    alt="Triple Window"
                  />
                  <div className="text-center item-name">
                    {t("Triple Window")}
                  </div>
                </div>
               </div> 
               <div
                className="col-4 lg:col-2 cursor-pointer"
                onClick={() => onTypeChange("Quadruple Window")}                
              > 
               <div className={`item-frame ${state.type === "Quadruple Window" ? "active-frame" : ""}`}>
                  <img
                    className="img-fluid img-item"
                    src={require("../assets/img/gallery.img/quadruple-window.jpg")}
                    alt="Quadruple Window"
                  />
                  <div className="text-center item-name">
                    {t("Quadruple Window")}
                  </div>
                </div>
               </div> 
               <div
                className="col-4 lg:col-2 cursor-pointer"
                onClick={() => onTypeChange("Other")}                
              > 
               <div className={`item-frame ${state.type === "Other" ? "active-frame" : ""}`}>
                  <img
                    className="img-fluid img-item"
                    src={require("../assets/img/gallery.img/other-window.jpg")}
                    alt="Other"
                  />
                  <div className="text-center item-name">
                    {t("Other")}
                  </div>
                </div>
               </div> 
            </div>
            <div className="flex flex-column mt-2">
              <div className="flex align-items-center">
                {t('Frame Width')}:&nbsp;
                <strong className="text-help">{state.width}</strong>&nbsp;
                {t('Inches')}
                <Button
                  className="p-button-help"
                  icon="pi pi-info-circle"
                  label={t("View Description")}
                  onClick={scrollToType}
                />
              </div>
              <InputNumber
                className="max-w-full lg:w-7rem"
                min={0}
                max={500}
                id="width"
                name="width"
                value={state.width}
                onChange={(e) => onChange("width", e.value)}
                showButtons
                buttonLayout="horizontal"
                step={1}
                decrementButtonClassName="p-button-primary"
                incrementButtonClassName="p-button-primary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
            </div>
            <div className="flex flex-column mt-3">
              <div className="flex align-items-center">
                {t('Height')}:&nbsp;
                <strong className="text-help">{state.height}</strong>
                &nbsp; {t('Inches')}
                <span>
                  <Button
                    className="p-button-help"
                    icon="pi pi-info-circle"
                    label={t("View Description")}
                    onClick={scrollTohight}
                  />
                </span>
              </div>
              <InputNumber
                className="max-w-full lg:w-7rem"
                min={0}
                max={120}
                id="height"
                name="height"
                value={state.height}
                onChange={(e) => onChange("height", e.value, false)}
                showButtons
                buttonLayout="horizontal"
                step={1}
                decrementButtonClassName="p-button-primary"
                incrementButtonClassName="p-button-primary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
            </div>
            <div className="flex flex-column mt-3">
              <div className="flex align-items-center">
                {t('Number of Pleats')}:&nbsp;
                <strong className="text-help">{state.pleats}</strong>
                <span>
                  <Button
                    className="p-button-help"
                    icon="pi pi-info-circle"
                    label={t("View Description")}
                    onClick={scrollTopleats}
                  />
                </span>
              </div>
              <div className="recommended-div">
                <InputNumber
                  className="max-w-full"
                  min={1}
                  id="pleats"
                  name="pleats"
                  value={state.pleats}
                  onChange={(e) => onChange("pleats", e.value, false)}
                  showButtons
                  buttonLayout="horizontal"
                  step={1}
                  decrementButtonClassName="p-button-primary"
                  incrementButtonClassName="p-button-primary"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                />
                <div className="recommended-div-2">
                  <label className="text-help">
                    {t('Recommended')} : {getRecommended()}
                  </label>
                </div>
              </div>
            </div>
            <br />
            <div className="grid">
              <div className="col-12 lg:col-7 gap-2 mt-2">
                <div className="el-switch flex align-items-center">
                  <label className="el-label-left">{t('Without Pole')}</label>
                  <InputSwitch
                    style={{ color: "#656565", marginLeft: "10px" }}
                    checked={state.isPole}
                    onChange={onPoleChange}
                  />
                  <label className="el-label-right ml-3">{t('With Pole')}</label>
                  <br />
                  <span>
                    <Button
                      className="p-button-help"
                      icon="pi pi-info-circle"
                      label={t("View Description")}
                      onClick={scrollTopoles}
                    />
                  </span>
                </div>
                <div className="mt-3">
                  <div>{t('Quantity')}</div>
                  <InputNumber
                    className="w-full lg:w-7rem mt-2"
                    min={1}
                    id="quantity"
                    name="quantity"
                    value={state.quantity}
                    onChange={(e) => onChange("quantity", e.value, false)}
                    showButtons
                    buttonLayout="horizontal"
                    step={1}
                    decrementButtonClassName="p-button-primary"
                    incrementButtonClassName="p-button-primary"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                  />
                </div>
              </div>
              <div className="col-12 lg:col-5 mt-4">
                <div className="border-dashed border-500 justify-content-center p-2 flex flex-column gap-2">
                  <strong>
                    {t('Price per unit')} : LKR {numberWithCommas(state.cost)}
                  </strong>
                  <strong>{t('Total')} : LKR {numberWithCommas(state.total)}</strong>
                </div>
                <div>
                  <Button
                    className="surface-700 mt-3 w-full"
                    label={t("Add to Cart")}
                    icon="pi pi-shopping-cart"
                    onClick={() => addToCart()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <h1 className="text-7xl mb-4 text-center">{t('User Guide')}</h1>
          <section>
            <h5 className="text-2xl">{t('How to measure?')}</h5>
            <div className="grid mt-3">
              <div className="col-12 lg:col-6 text-center">
                <img
                  className="img-measure w-1/4"
                  src={require("../assets/img/gallery.img/how-to-measure.jpg")}
                  alt="Measurement Guide"
                />
              </div>
              <div className="col-12 lg:col-6 lg:px-4">
                <h5 style={{ fontSize: "20px" }}>
                  {t('All measurements should be in inches')} <br />
                </h5>
                <div className="measurement-points">
                  <ul
                    style={{
                      gap: "20px",
                      paddingLeft: "0",
                    }}
                  >
                    <div className="measurement-list">
                      <div className="measurment-list-order">
                        <li>A</li>
                      </div>
                      <div>
                        <li>{t('Width of the Window/Door outer frame')}</li>
                      </div>
                    </div>
                    <div className="measurement-list">
                      <div className="measurment-list-order">
                        <li>B</li>
                      </div>
                      <div>
                        <li>{t('Height of the Window/Door outer frame')}</li>
                      </div>
                    </div>
                    <div className="measurement-list">
                      <div className="measurment-list-order">
                        <li>C</li>
                      </div>
                      <div>
                        <li>
                          {t('Measurement from the floor to the bottom of the window')}
                          {t('frame')}
                        </li>
                      </div>
                    </div>
                    <div className="measurement-list">
                      <div className="measurment-list-order">
                        <li>D</li>
                      </div>
                      <div>
                        <li>
                          {t('Measurement from the ceiling/ roof/ slab to the top of')}
                          {t('the window frame')}
                        </li>
                      </div>
                    </div>
                    <div className="measurement-list">
                      <div className="measurment-list-order">
                        <li>E</li>
                      </div>
                      <div>
                        <li>
                          {t('Full height from the floor to the ceiling/ slab/ roof')}
                          {t('(Not essential)')}
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <h5 className="text-2xl mt-6">{t('Type & Width')}</h5>
          <div ref={typeRef}>
            <p>{t('The width of door & windows are predicted as below')}</p>
            <div className="design-types row">
              <div className="col-12 lg:col-4 flex justify-content-center">
                <img
                  className="img-measure img-fluid "
                  src={require("../assets/img/gallery.img/door-type.jpg")}
                  alt="Width 1"
                />
              </div>
              <div className="col-12 lg:col-4 flex justify-content-center">
                <img
                  className="img-measure img-fluid"
                  src={require("../assets/img/gallery.img/single-type.jpg")}
                  alt="Width 2"
                />
              </div>
              <div className="col-12 lg:col-4 flex justify-content-center">
                <img
                  className="img-measure img-fluid"
                  src={require("../assets/img/gallery.img/double-type.jpg")}
                  alt="Width 3"
                />
              </div>
              <div className="col-12 lg:col-4 flex justify-content-center">
                <img
                  className="img-measure img-fluid"
                  src={require("../assets/img/gallery.img/triple-type.jpg")}
                  alt="Width 4"
                />
              </div>
              <div className="col-12 lg:col-4 flex justify-content-center">
                <img
                  className="img-measure img-fluid"
                  src={require("../assets/img/gallery.img/quadruple-type.jpg")}
                  alt="Width 5"
                />
              </div>
              <div className="col-12 lg:col-4 flex justify-content-center">
                <img
                  className="img-measure img-fluid"
                  src={require("../assets/img/gallery.img/other-type.jpg")}
                  alt="Width 6"
                />
              </div>
            </div>
            <div>
              <p className="flex justify-content-center">
                {t('Depending on the size of the door and window frame, add or')}
                {t('subtract the value in the Width box.')}
              </p>
              <div
                className="content-wrapper"
                style={{
                  borderradius: "8px",
                  border: "1px dashed #656565",
                  width: "350px",
                  height: "100px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexdirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  {t('Frame Width')} ({t('Inches')})
                  <span>
                    <Button
                      className="p-button-help"
                      icon="pi pi-info-circle"
                      label="View Description"
                    />
                  </span>
                </div>
                <div className="flex justify-content-center">
                  <img
                    style={{
                      width: "200px",
                      height: "50px",
                    }}
                    src={require("../assets/img/gallery.img/width-frame.jpg")}
                    alt="Width frame"
                  />
                </div>
              </div>
            </div>
            <p className="flex justify-content-center">
              {t('Simply specify the inside width of your window frame and we will')}
              {t('increase it by 6 inches on each side.')}
            </p>
          </div>
          <h5 className="text-2xl mt-6">{t('Height')}</h5>
          <div ref={hightRef}
            className="grid"
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="col-12 lg:col-5 lg:px-4 ">
              <img
                className="item-image-2"
                src={require("../assets/img/gallery.img/height-frame.jpg")}
                alt="Height Guide"
              />
            </div>
            <div className="col-12 lg:col-7 lg:px-4">
              <p style={{ fontSize: "20px" }}>
                {t('It is adequate to mention the necessary height in inches and the')}
                {t('cost differs according to three sizes of height.')}
              </p>
              <div>
                <div className="height-details">
                {t('1. Less than 47 inches (this reduce a half of the cost of')}
                {t('material in your design) Conditions Apply')}
                </div>
                <div className="height-details">
                {t('2. From 48 inches up to 102 inches')}
                </div>
                <div className="height-details">
                {t('3. From 103 inches to above')}
                </div>
                <div className="height-details">{t('Notice')}:</div>
                <div className="height-details">
                {t('If 100 inches height is maintained a fantastic finish is')}
                {t('obtainable for your design')}
                </div>
              </div>
              <div className="height-measurement-div content-wrapper">
                <div
                  style={{
                    display: "flex",
                    flexdirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  {t('Height')} ({t('Inches')})
                  <span>
                    <Button
                      className="p-button-help"
                      icon="pi pi-info-circle"
                      label="View Description"
                    />
                  </span>
                </div>
                <div className="flex justify-content-center">
                  <img
                    style={{
                      width: "200px",
                      height: "50px",
                    }}
                    src={require("../assets/img/gallery.img/height-mesurement-frame.jpg")}
                    alt="pleat frame"
                  />
                </div>
              </div>
            </div>
          </div>
          <h5 className="text-2xl mt-6">{t('Pleats')}</h5>
          <div ref={pleatsRef}
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <p>
                {t('Usual practice is inserting 3 pleats per foot (The International')}
                {t('Standards is 14 inch gap between two pleats). Accordingly, a')}
                {t('quantity of material is been used,')}
              </p>
            </div>
            <div className="grid">
              <div className="col-12 lg:col-6 lg:px-4 flex justify-content-center">
                <img
                  className="item-image-2"
                  src={require("../assets/img/gallery.img/pleats-frame-1.jpg")}
                  alt="pleats Guide"
                />
              </div>
              <div className="col-12 lg:col-6 lg:px-4">
                <div className="grid flex justify-content-center mt-6">
                  <img
                    className="col-6 lg:col-4"
                    src={require("../assets/img/gallery.img/pleats-frame-2.jpg")}
                    alt="pleats frame 2"
                  />
                  <img
                    className="col-6 lg:col-4"
                    src={require("../assets/img/gallery.img/pleats-frame-3.jpg")}
                    alt="pleats frame 3"
                  />
                  <img
                    className="col-6 lg:col-4"
                    src={require("../assets/img/gallery.img/pleats-frame-4.jpg")}
                    alt="pleats frame 4"
                  />
                  <img
                    className="col-6 lg:col-4"
                    src={require("../assets/img/gallery.img/pleats-frame-5.jpg")}
                    alt="pleats frame 5"
                  />
                  <img
                    className="col-6 lg:col-4"
                    src={require("../assets/img/gallery.img/pleats-frame-6.jpg")}
                    alt="pleats frame 6"
                  />
                </div>
              </div>
            </div>
            <div className="pleats-design">
              <p>
                {t('The number of pleats can be decreased or increased according to')}
                {t('your probability and requirement. The price depends with number')}
                {t('of pleats on your decision.')}
              </p>
            </div>
            <div className="pleats-design">{t('Notice')}:</div>
            <div className="pleats-design" style={{ marginLeft: "30px" }}>
              {t('If transparent curtains (sheer) are used for your window frames it')}
              {t('is appropriate to increase a few number of pleats')}
            </div>
            <div
              className="content-wrapper mt-4"
              style={{
                borderradius: "8px",
                border: "1px dashed #656565",
                width: "350px",
                height: "100px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexdirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {t('Number of Pleats')}
                <span>
                  <Button
                    className="p-button-help"
                    icon="pi pi-info-circle"
                    label="View Description"
                  />
                </span>
              </div>
              <div className="flex justify-content-center">
                <img
                  style={{
                    width: "200px",
                    height: "50px",
                  }}
                  src={require("../assets/img/gallery.img/pleats-frame-7.jpg")}
                  alt="Width frame"
                />
              </div>
            </div>
          </div>
          <h5 className="text-2xl mt-6">{t('Poles')}</h5>
          <div ref={polesRef}
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <p>
                {t('For the purpose of curtain brackets of or own designs have been')}
                {t('utilized to date and it is made of standard galvanized tubes and')}
                {t('quality wood. A long time warranty is granted and the benefits')}
                {t('you enjoy are,')}
              </p>
            </div>
            <div className="pole-design">
            {t('As we charge only the production cost the prices prevail at a low')}
            {t('rate.')}
            </div>
            <div className="pole-design">
            {t('As the bracket is provided with screws for installing facility and')}
            {t('It is convenient to fix on the wall.')}
            </div>
            <div className="pole-design">
            {t('Depending on the location of walls and windows our brackets')}
            {t('consists of various shaped accessories.')}
            </div>
            <div className="pole-design">
            {t('The fastener is furnished with a good quality wood (with no')}
            {t('adhesive) the security is high.')}
            </div>
            <div className="pole-design">{t('It is long lasting')}</div>
            <div className="pole-design">
            {t('Attractive and available in many colours')}
            </div>
            <div className="pole-design">
            {t('Being able to contribute to saving the environment as less wood is')}
            {t('used.')}
            </div>
            <div
              className="grid"
              style={{
                marginTop: "30px",
                display: "flex",
                flexdirection: "row",
              }}
            >
              <div className="col-12 lg:col-5 flex justify-content-center">
                <img
                  src={require("../assets/img/gallery.img/pole-frame.jpg")}
                  alt="pole frame"
                />
              </div>
              <div className="col-12 lg:col-7">
                <div>
                {t('As this is our own design this is not available in the open')}
                {t('market & accessories such as poles are not issued on your')}
                {t('request.')}
                </div>
                <div style={{ marginTop: "20px" }}>
                {t('You can select from here whether you need or not a pole')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Item;