import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";

import { useLanguage } from "language-maps/language-context";

import "../assets/css/home.css";
import "../assets/css/popup.css";

import c1 from "../assets/img/popup.img/c1.png";
import c2 from "../assets/img/popup.img/c2.png";
import c3 from "../assets/img/popup.img/c3.png";
import c4 from "../assets/img/popup.img/c5.png";
import c5 from "../assets/img/popup.img/c4.png";
import c6 from "../assets/img/popup.img/c6.png";

const OfferPopup = ({ onClose }) => {
  const [showPopup, setShowPopup] = useState(false);
  const { language, setLanguage } = useLanguage();
  const popupRef = useRef(null);
  const [products, setProducts] = useState([]);

  const productTemplate = (product) => {
    return (
      <Link to={`/item/${product.code}`}>
        <img className="w-full" src={product.image} alt="Image not found" />
      </Link>
    );
  };

  useEffect(() => {
    const updateProductsForMobile = () => {
      if (window.innerWidth <= 768) {
        setProducts([
          { image: c4, code: "c4" },
          { image: c5, code: "c5" },
          { image: c6, code: "c6" },
        ]);
      } else {
        setProducts([
          { image: c1, code: "c1" },
          { image: c2, code: "c2" },
          { image: c3, code: "c3" },
        ]);
      }
    };

    updateProductsForMobile();
    window.addEventListener("resize", updateProductsForMobile);

    return () => {
      window.removeEventListener("resize", updateProductsForMobile);
    };
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
    setShowPopup(true);
  }, [setLanguage]);

  const handleClose = () => {
    setShowPopup(false);
    if (onClose) onClose();
  };

  const handleClickOutside = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    localStorage.setItem("selectedLanguage", lang);
  };

  if (!showPopup) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container" ref={popupRef}>
        <Carousel
          value={products}
          circular
          itemTemplate={productTemplate}
          className="pt-3 pb-6 cursor-pointer pop-up-carousel"
          autoplayInterval={3000}
        />
        <div className="language-container">
          <div className="flex justify-content-between">
            <div className="flex gap-2 align-items-center px-2">
              <div className="lg:mr-2 text-sm">Select Your Language</div>
              <Button
                label="English"
                className={
                  language === "en"
                    ? "p-button-primary"
                    : "p-button-text  p-button-white"
                }
                style={{ height: "30px" }}
                onClick={() => handleLanguageChange("en")}
              />
              <Button
                label="සිංහල"
                className={
                  language === "si"
                    ? "p-button-primary"
                    : "p-button-text p-button-white"
                }
                style={{ height: "30px" }}
                onClick={() => handleLanguageChange("si")}
              />
            </div>
            <Button
              className="p-button-text text-white"
              icon="pi pi-times"
              onClick={handleClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferPopup;
