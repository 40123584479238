export const contactUs = {

  "Get in": {
    en: "Get in",
    si: "ඇතුළුවන්න",
  },
  "Touch": {
    en: "Touch",
    si: "ස්පර්ශය කරන්න",
  },
  "Today": {
    en: "Today",
    si: "අද",
  },
  "Hotline": {
    en: "Hotline",
    si: "හොට්ලයින්", 
    
  },
  "Email": {
    en: "Email",
    si: "විද්යුත් තැපැල්",
  },
  "Facebook": {
    en: "Facebook",
    si: "ෆේස්බුක්",
  },
  "YouTube": {
    en: "YouTube",
    si: "යූටියුබ්",
  },
  "Meet Us": {
    en: "Meet Us",
    si: "අප සමඟ හමුවන්න",
  }
  
};
