export const gallery = {

  "Gallery": { en: "Gallery", si: "ගැලරිය" },
  "of Elegance": { en: "of Elegance", si: "ශිල්පී" },
  "Explore our wide range of latest curtain designs that will match all your needs at the most affordable price in Sri Lanka": {
    en: "Explore our wide range of latest curtain designs that will match all your needs at the most affordable price in Sri Lanka",
    si: "ඔබේ සියලුම අවශ්‍යතා සපුරන, ශ්‍රී ලංකාවේ වඩාත්ම අඩු මිලට ලබා ගත හැකි තාක්ෂණයේ පරම නවීන තිර සැලසුම් පරිදි විවෘත කරන්න"
  },
  "Added: New to Old": { en: "Added: New to Old", si: "අලුත් සිට පරණ" },
  "Added: Old to New": { en: "Added: Old to New", si: "පරණ සිට අලුත්" },
  "Price: High to Low": { en: "Price: High to Low", si: "මිල: අධික සිට අඩු" },
  "Price: Low to High": { en: "Price: Low to High", si: "මිල: අඩු සිට අධික" },
  "Sort By Price": { en: "Sort By Price", si: "මිල අනුව තෝරන්න" },
  "Per Meter": { en: "Per Meter", si: "මීටරයකට" },
  "Search": { en: "Search", si: "සොයන්න" },
  "Image not found": { en: "Image not found", si: "පින්තූරය හමු නොවීය" },
  
};