export const home = {

  "Curtains": {
    en: "Curtains",
    si: "ඇඳුම්",
  },
  "That":{
    en: "That",
    si: "ඒවා",
  }, 
  "Define Style": {
    en: "Define Style",
    si: "ශෛලිය නිර්වචනය කරයි",
  },
  "Explore Our Range": {
    en: "Explore Our Range",
    si: "අපගේ පරාසය සොයන්න",
  },
  "Home and": {
    en: "Home and",
    si: "නිවස සහ",
  },
  "Islandwide": {
    en: "Islandwide",
    si: "දිවයින පුරා",
  },
  "Service": {
    en: "Service",
    si: "සේවාව",
  },
  "Consultation": {
    en: "Consultation",
    si: "සංවාදය",
  },
  "and Advice": {
    en: "and Advice",
    si: "සහ උපදෙස්",
  },
  "Office Solutions": {
    en: "Office Solutions",
    si: "කාර්යාල විසඳුම්",
  },
  "Satisfaction": {
    en: "Satisfaction",
    si: "සන්තෘප්තියේ",
  },
  "Guarantee": {
    en: "Guarantee",
    si: "සහතිකය",
  },
  "Innovative": {
    en: "Innovative",
    si: "නවෝත්පාදන",
  },
  "options": {
    en: "options",
    si: "විකල්ප",
  },
  "for the modern world": {
    en: "for the modern world",
    si: "අද කාලය සඳහා",
  },
  "Geeth Curtains": {
    en: "Geeth Curtains",
    si: "ගීත ක්‍රටින්ස්",
  },
  "Over a decade ago, we embarked on our entrepreneurial": {
    en: "Over a decade ago, we embarked on our entrepreneurial",
    si: "දශකයකට පමණ පෙර, අපි අපගේ ව්‍යවසායකත්වය ආරම්භ කළෙමු",
  },
  "journey with the launch of Geeth Curtains near Walpola": {
    en: "journey with the launch of Geeth Curtains near Walpola",
    si: "වල්පොල අසල ගීත් තිර රෙදි එළිදැක්වීමත් සමඟ ගමන",
  },
  "railway station. Throughout these twelve years, we": {
    en: "railway station. Throughout these twelve years, we",
    si: "දුම්රිය ස්ථානය. මේ අවුරුදු දොළහ පුරාවට අපි",
  },
  "ve dedicated ourselves to providing honest, cooperative, and": {
    en: "dedicated ourselves to providing honest, cooperative, and",
    si: "අවංක, සමුපකාර සහ සැපයීම සඳහා අප කැපවී සිටිමු",
  },
  "amicable service to our valued customers. We": {
    en: "'amicable service to our valued customers. We'",
    si: "අපගේ වටිනා පාරිභෝගිකයින්ට සුහදශීලී සේවාවක්. අපි",
  },
  "ve strived": {
    en: "strived",
    si: "උත්සාහ කළා",
  },
  "to understand your heartfelt inspirations, wishes, economic": {
    en: "to understand your heartfelt inspirations, wishes, economic",
    si: "ඔබේ හෘදයාංගම ආශ්වාදයන්, පැතුම්, ආර්ථික තේරුම් ගැනීමට",
  },
  "constraints, and requirements.": {
    en: "constraints, and requirements.",
    si: "සීමාවන් සහ අවශ්යතා.",
  },
  "In response to the trust and support bestowed upon us by our": {
    en: "In response to the trust and support bestowed upon us by our",
    si: "අප වෙත ලබා දී ඇති විශ්වාසය සහ සහයෝගයට ප්‍රතිචාර වශයෙන්",
  },
  "thousands of sincere customers, we": {
    en: "thousands of sincere customers, we",
    si: "දහස් ගණන් අවංක ගනුදෙනුකරුවන්, අපි",
  },
  "ve expanded our": {
    en: "ve expanded our",
    si: "අපේ පුළුල් කළා",
  },
  "presence. Today, we proudly announce the opening of our new": {
    en: "presence. Today, we proudly announce the opening of our new",
    si: "සිටීම. අද, අපි අපගේ නව විවෘත කිරීම ආඩම්බරයෙන් නිවේදනය කරමු",
  },
  "showroom opposite Ragama Railway Station. This expansion": {
    en: "showroom opposite Ragama Railway Station. This expansion",
    si: "රාගම දුම්රිය ස්ථානය ඉදිරිපිට ප්‍රදර්ශනාගාරය. මෙම පුළුල් කිරීම",
  },
  "marks our commitment to enhancing our service and meeting": {
    en: "marks our commitment to enhancing our service and meeting",
    si: "අපගේ සේවාව සහ රැස්වීම වැඩිදියුණු කිරීම සඳහා අපගේ කැපවීම සනිටුහන් කරයි",
  },
  "your needs more effectively. With three additional branches": {
    en: "your needs more effectively. With three additional branches",
    si: "ඔබේ අවශ්‍යතා වඩාත් ඵලදායී ලෙස. අමතර ශාඛා තුනක් සමඟ",
  },
  "and a team of skilled staff, we are now known as Geeth": {
    en: "and a team of skilled staff, we are now known as Geeth",
    si: "සහ දක්ෂ කාර්ය මණ්ඩලයක්, අපි දැන් ගීත් ලෙස හැඳින්වේ",
  },
  "Curtain Designs, an esteemed and renowned entrepreneur.": {
    en: "Curtain Designs, an esteemed and renowned entrepreneur.",
    si: "ගීත ක්‍රටින්ස්, ගෞරවනීය සහ කීර්තිමත් ව්‍යවසායකයෙක්.",
  },
  "Our journey": {
    en: "Our journey",
    si: "අපේ ගමන",
  },
  "s success isn": {
    en: "s success isn",
    si: "සාර්ථකත්වය යනු",
  },
  "t measured by mere": {
    en: "t measured by mere",
    si: "හුදෙක් විසින් මනිනු ලැබේ",
  },
  "achievements but by the enduring affection, cooperation, and": {
    en: "achievements but by the enduring affection, cooperation, and",
    si: "ජයග්රහණ නමුත් කල්පවත්නා සෙනෙහස, සහයෝගීතාවය සහ",
  },
  "trust you": {
    en: "trust you",
    si: "ඔබව විශ්වාස කරන්න",
  },
  "ve bestowed upon us. As we look to the": {
    en: "ve bestowed upon us. As we look to the",
    si: "අපට ලබා දී ඇත. අපි දෙස බලන විට",
  },
  "future, we are eager to continue providing unparalleled,": {
    en: "future, we are eager to continue providing unparalleled,",
    si: "අනාගතයේදී, අසමසම ලෙස සැපයීමට අපි උනන්දු වෙමු,",
  },
  "friendly service. Your unwavering support inspires us to": {
    en: "friendly service. Your unwavering support inspires us to",
    si: "මිත්රශීලී සේවය. ඔබගේ නොසැලෙන සහයෝගය අපව දිරිමත් කරයි",
  },
  "strive for excellence in everything we do.": {
    en: "strive for excellence in everything we do.",
    si: "අප කරන සෑම දෙයකම විශිෂ්ටත්වය සඳහා උත්සාහ කරන්න.",
  },
  "Crafted for": {
    en: "Crafted for",
    si: "සඳහා නිර්මාණය කර ඇත",
  },
  "Luxury": {
    en: "Luxury",
    si: "සුඛෝපභෝගී",
  },
  "We prioritize efficiency and affordability by leveraging modern": {
    en: "We prioritize efficiency and affordability by leveraging modern",
    si: "නවීනත්වය උපයෝගී කර ගනිමින් අපි කාර්යක්ෂමතාව සහ දැරිය හැකි මිලට ප්‍රමුඛත්වය දෙමු",
  },
  "technology in our production processes, ensuring minimized costs": {
    en: "technology in our production processes, ensuring minimized costs",
    si: "අපගේ නිෂ්පාදන ක්‍රියාවලීන්හි තාක්‍ෂණය, අවම පිරිවැය සහතික කිරීම",
  },
  "for you. With a diverse range of curtain designs available, we": {
    en: "for you. With a diverse range of curtain designs available, we",
    si: "ඔයා වෙනුවෙන්. ලබා ගත හැකි විවිධාකාර තිර මෝස්තර සමඟ, අපි",
  },
  "cater to various preferences and budgets. Additionally, our": {
    en: "cater to various preferences and budgets. Additionally, our",
    si: "විවිධ මනාපයන් සහ අයවැය වලට අනුකූල වේ. මීට අමතරව, අපගේ",
  },
  "rapid service guarantees prompt delivery, even for urgent": {
    en: "rapid service guarantees prompt delivery, even for urgent",
    si: "කඩිනම් සේවාව හදිසි අවස්ථාවකදී පවා ඉක්මනින් බෙදා හැරීම සහතික කරයි",
  },
  "orders, right to your doorstep.": {
    en: "orders, right to your doorstep.",
    si: "ඇණවුම්, ඔබේ දොරකඩටම.",
  },
  "At Geeth Curtain Designs, we maintain extensive material stocks,": {
    en: "At Geeth Curtain Designs, we maintain extensive material stocks,",
    si: "ගීත ක්‍රටින්ස් හි, අපි පුළුල් ද්‍රව්‍ය තොග පවත්වාගෙන යනවා,",
  },
  "unaffected by prevailing inflation rates. Coupled with years of": {
    en: "unaffected by prevailing inflation rates. Coupled with years of",
    si: "පවතින උද්ධමන අනුපාතවලින් බලපෑමක් නැත. වසර සමඟ සම්බන්ධ වී ඇත",
  },
  "craftsmanship experience, we promise efficient and reliable": {
    en: "craftsmanship experience, we promise efficient and reliable",
    si: "ශිල්පීය අත්දැකීම්, අපි කාර්යක්ෂම සහ විශ්වසනීය පොරොන්දු වෙනවා",
  },
  "service. Our expertise in modern technology enables us to offer": {
    en: "service. Our expertise in modern technology enables us to offer",
    si: "සේවය. නවීන තාක්‍ෂණය පිළිබඳ අපගේ ප්‍රවීණත්වය අපට පිරිනැමීමට හැකියාව ලබා දෙයි",
  },
  "tailored designs that perfectly suit your needs and preferences.": {
    en: "tailored designs that perfectly suit your needs and preferences.",
    si: "ඔබේ අවශ්‍යතා සහ මනාපයන් සඳහා මනාව ගැලපෙන පරිදි සකස් කරන ලද මෝස්තර.",
  },
  "Customer satisfaction is our utmost priority. If you": {
    en: "Customer satisfaction is our utmost priority. If you",
    si: "පාරිභෝගික තෘප්තිය අපගේ උපරිම ප්‍රමුඛතාවයයි. ඔබ නම්",
  },
  "re ever dissatisfied with our service, we offer a no-deduction": {
    en: "re ever dissatisfied with our service, we offer a no-deduction",
    si: "අපගේ සේවාව ගැන සෑහීමකට පත් නොවන්නේ නම්, අපි කිසිදු අඩු කිරීමක් පිරිනමන්නෙමු",
  },
  "refund policy. Moreover, our in-house manufacturing of curtain": {
    en: "refund policy. Moreover, our in-house manufacturing of curtain",
    si: "ආපසු ගෙවීමේ ප්රතිපත්තිය. එපමණක් නොව, අපගේ ගෘහස්ථ තිර රෙදි නිෂ්පාදනය කිරීම",
  },
  "poles ensures both aesthetic appeal and durability, enhancing": {
    en: "poles ensures both aesthetic appeal and durability, enhancing",
    si: "පොලු සෞන්දර්යාත්මක ආකර්ෂණය සහ කල්පැවැත්ම යන දෙකම සහතික කරයි, වැඩි දියුණු කරයි",
  },
  "the overall look of your curtains. With flexible payment options": {
    en: "the overall look of your curtains. With flexible payment options",
    si: "ඔබේ තිර රෙදි වල සමස්ත පෙනුම. නම්‍යශීලී ගෙවීම් විකල්ප සමඟ",
  },
  "and convenient consultation timings, we strive to make your": {
    en: "and convenient consultation timings, we strive to make your",
    si: "ඔබේ තිර රෙදි වල සමස්ත පෙනුම. නම්‍යශීලී ගෙවීම් විකල්ප සමඟ",
  },
  "experience with us seamless and satisfying.": {
    en: "experience with us seamless and satisfying.",
    si: "අප සමඟ අත්දැකීම් බාධාවකින් තොරව සහ තෘප්තිමත් වේ.",
  },
  "Vlogs": {
    en: "Vlogs",
    si: "Vlogs",
  },
  "Recent": {
    en: "Recent",
    si: "මෑත",
  },
  "How to Measure": {
    en: "How to Measure",
    si: "මැනිය යුතු ආකාරය",
  },
  "How to Use the Website": {
    en: "How to Use the Website",
    si: "වෙබ් අඩවිය භාවිතා කරන ආකාරය",
  },
  "Quality of Curtains": {
    en: "Quality of Curtains",
    si: "තිර රෙදි වල ගුණාත්මකභාවය",
  },
  "GEETH CURTAINS": {
    en: "GEETH CURTAINS",
    si: "ගීත ක්‍රටින්ස්",
  },
  "Innovative Options for the Modern World": {
    en: "Innovative Options for the Modern World",
    si: "නවීන ලෝකය සඳහා නවෝත්පාදන විකල්ප",
  },
  "Watch Now": {
    en: "  Watch Now",
    si: "දැන් නරඹන්න",
  },

};