import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import { store } from "../store";

import { numberWithCommas } from "../CustomMath";
import MailService from "../mailService";

import "../assets/css/cart.css";

const initialState = {
  id: 0,
  title: "",
  firstName: "",
  lastName: "",
  telephone: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  distance: 0,
  totalQty: 0,
  totalWidth: 0,
  subTotal: 0,
  totalPleats: 0,
  transportationCost: 0,
  fixingCost: 0,
  totalFabricCost: 0,
  total: 0,
  products: [],
};

const AdminCart = () => {
  const [state, setState] = useState({ ...initialState });
  const [dialog, setDialog] = useState(false);
  const [fixing, setFixing] = useState(false);
  const [transport, setTransport] = useState(false);
  const [distance, setDistance] = useState("");

  const service = new MailService();

  useEffect(() => {
    const items = store.getState().items;

    const temp = { ...initialState };
    temp.products = items;

    calculateTotal(temp);
  }, []);

  function calculateFixingCost(e) {
    setFixing(e.value);

    const temp = state;
    temp.fixingCost = 0;

    if (e.value) {
      temp.fixingCost = (state.totalQty <= 5 ? 200 : 150) * state.totalQty;
    }

    setState((prevState) => ({ ...prevState, fixingCost: temp.fixingCost }));
    calculateTotal(temp);
  }

  function calculateTransportCost(e) {
    if (isNaN(e.target.value)) return;

    setDistance(e.target.value);

    const temp = state;
    temp.transportationCost = e.target.value * 150;

    setState((prevState) => ({
      ...prevState,
      transportationCost: temp.transportationCost,
    }));

    calculateTotal(temp);
  }

  function toggleDistance(e) {
    setTransport(e.value);
    setDistance("");
    setState((prevState) => ({
      ...prevState,
      transportationCost: 0,
    }));
  }

  const calculateTotal = (tempState) => {
    if (tempState.products) {
      tempState.totalFabricCost = 0;
      tempState.subTotal = 0;
      tempState.totalWidth = 0;
      tempState.totalQty = 0;
      tempState.totalPleats = 0;

      tempState.products.forEach(function (p) {
        tempState.totalFabricCost += p.item.fabricCost;
        tempState.subTotal += +p.item.total;

        if (p.item.isPole) {
          tempState.totalWidth += p.item.quantity * p.item.width + 12;
        }

        tempState.totalQty += +p.item.quantity;
        tempState.totalPleats += +p.item.pleats * p.item.quantity;
        tempState.total =
          tempState.subTotal +
          tempState.fixingCost +
          tempState.transportationCost;
      });
    }

    setState(tempState);
  };

  const removeItem = (id) => {
    store.dispatch({
      type: "itemRemoved",
      payload: id,
    });

    const updatedProducts = state.products.filter((rem) => rem.id !== id);

    setState((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));

    const tempState = { ...state, products: updatedProducts };
    calculateTotal(tempState);
  };

  const clearCart = () => {
    store.dispatch({
      type: "itemsCleared",
      payload: [],
    });

    const tempState = { ...initialState };

    setState(tempState);
    calculateTotal(tempState);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    const temp = { ...state };
    temp[name] = value;
    if (name === "distance" && state.transportCostEnabled) {
      temp["transportationCost"] = value * 150;
    }
    setState(temp);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    sendMail();
  };

  const sendMail = async () => {
    const payload = { ...state };

    const order = {
      customerDetails: {
        title: payload.title,
        firstName: payload.firstName,
        lastName: payload.lastName,
        addressLine1: payload.addressLine1,
        addressLine2: payload.addressLine2,
        email: payload.email,
        telephone: payload.telephone,
      },
      orderItems: [],
      orderDetails: {
        distance: distance || 0,
        fixingCost: payload.fixingCost,
        subTotal: payload.subTotal,
        total: payload.total,
        totalFabricCost: payload.totalFabricCost,
        totalPleats: payload.totalPleats,
        totalQty: payload.totalQty,
        totalWidth: inchesToFeet(payload.totalWidth),
        transportationCost: payload.transportationCost,
      },
    };

    payload.products.forEach((element) => {
      order.orderItems.push({
        selectedDesign: element.item.selectedDesign,
        cost: element.item.cost,
        fabricCost: element.item.fabricCost,
        fabricPrice: element.item.fabricPrice,
        height: element.item.height,
        image: element.item.image,
        poleRequired: element.item.isPole,
        pleats: element.item.pleats,
        price: element.item.price,
        quantity: element.item.quantity,
        total: element.item.total,
        type: element.item.type,
        width: element.item.width,
      });
    });

    setDialog(true);

    const result = await service.postToEndPoint(order);
    if (result.id) clearCart();

    setDialog(false);
  };

  const inchesToFeet = (inches) => {
    return inches ? Math.ceil(inches / 12) : 0;
  };

  const renderListItem = (dt) => {
    const item = dt.item.selectedDesign;
    return (
      <div>
        <div className="flex w-full mb-3">
          <img
            className="cart-item-image"
            src={`https://firebasestorage.googleapis.com/v0/b/geethcuratins.appspot.com/o/${item.Image}?alt=media&token=debc5e8a-2c21-45a3-b0d6-50de7f8166d7`}
            alt="not found"
            loading="lazy"
          />
          <div className="flex-1 flex justify-content-between ml-3">
            <div className="flex flex-column gap-1">
              <h4>{item.Name}</h4>
              <h5 className="item-specs">Item Code: {item.Code}</h5>
              <div className="desktop-specs">{renderItemSpecs(dt.item)}</div>
              <div className="text-sm">
                Unit Price: LKR {numberWithCommas(dt.item.cost)}
              </div>
              <div className="text-sm">Quantity: {dt.item.quantity}</div>
              <div className="font-semibold text-sm">
                Total: LKR {numberWithCommas(dt.item.total)}
              </div>
              <Button
                style={{ height: "36px" }}
                icon="pi pi-trash"
                className="p-button-secondary mobile-specs"
                onClick={() => removeItem(dt.id)}
              />
            </div>
            <Button
              icon="pi pi-trash"
              rounded
              className="p-button-secondary desktop-specs"
              onClick={() => removeItem(dt.id)}
            />
          </div>
        </div>
        <div className="mobile-specs mb-4">{renderItemSpecs(dt.item)}</div>
      </div>
    );
  };

  const renderItemSpecs = (item) => {
    return (
      <div className="flex gap-2 text-sm">
        <div className="chip px-2 py-1">
          <div className="border-bottom-1">Type</div>
          <div className="text-center">{item.type}</div>
        </div>
        <div className="chip px-2 py-1">
          <div className="border-bottom-1">Width</div>
          <div className="text-center">{item.width}</div>
        </div>
        <div className="chip px-2 py-1">
          <div className="border-bottom-1">Height</div>
          <div className="text-center">{item.height}</div>
        </div>
        <div className="chip px-2 py-1">
          <div className="border-bottom-1">Pleats</div>
          <div className="text-center">{item.pleats}</div>
        </div>
        <div className="chip px-2 py-1">
          <div className="border-bottom-1">Pole</div>
          <div className="text-center">{item.isPole ? "Yes" : "No"}</div>
        </div>
      </div>
    );
  };

  const renderAdditionalServices = () => {
    return (
      <div className="flex flex-column gap-2 mb-3">
        <div className="flex justify-content-between">
          <label>Fixing Required</label>
          <InputSwitch
            checked={fixing}
            onChange={(e) => calculateFixingCost(e)}
          />
        </div>
        <hr />
        <div className="flex justify-content-between align-items-center">
          <label>Transport Required</label>
          <div className="flex gap-2 align-items-center">
            {transport ? (
              <InputText
                required
                value={distance}
                onChange={(e) => calculateTransportCost(e)}
                placeholder="Distance: KM"
                className="p-inputtext-sm"
                style={{ maxWidth: "120px" }}
              />
            ) : null}
            <InputSwitch
              checked={transport}
              onChange={(e) => toggleDistance(e)}
            />
          </div>
        </div>
        <hr />
      </div>
    );
  };

  const renderSummery = () => {
    return (
      <div className="card">
        <h3 className="text-center">Order Summary</h3>
        {renderAdditionalServices()}
        <div className="grid">
          <div className="col-12 flex flex-column gap-2">
            <div className="flex justify-content-between">
              <label>Total Quantities</label>
              <label>{state.totalQty}</label>
            </div>
            <div className="flex justify-content-between">
              <label>Pole Width</label>
              <label>{inchesToFeet(state.totalWidth)} ft</label>
            </div>
            <div className="flex justify-content-between">
              <label>Total Pleats</label>
              <label>{state.totalPleats}</label>
            </div>
            <hr />
            <div className="flex justify-content-between">
              <label>Fixing Cost</label>
              <label>LKR {numberWithCommas(state.fixingCost)}</label>
            </div>
            <div className="flex justify-content-between">
              <label>Transportation Cost</label>
              <label>LKR {numberWithCommas(state.transportationCost)}</label>
            </div>
            <hr />
            <div className="cart-total">
              <span>Total</span>
              <span>LKR {numberWithCommas(state.total)}</span>
            </div>
          </div>
          <div className="col-12 flex flex-column pt-3">
            <strong>Please fill your details to get your quotation.</strong>
            <strong>You will receive the quote via an Email</strong>
            <form onSubmit={onSubmit} className="flex flex-column gap-2 mt-3">
              <div className="flex justify-content-between">
                <InputText
                  name="title"
                  required
                  value={state.title}
                  onChange={onChange}
                  placeholder="Title"
                  style={{ width: "60px" }}
                />
                <InputText
                  className="cart-names"
                  name="firstName"
                  required
                  value={state.firstName}
                  onChange={onChange}
                  placeholder="First Name"
                />
                <InputText
                  className="cart-names"
                  name="lastName"
                  value={state.lastName}
                  onChange={onChange}
                  placeholder="Last Name"
                />
              </div>
              <InputText
                name="telephone"
                type="tel"
                required
                value={state.telephone}
                onChange={onChange}
                placeholder="Telephone / Whatsapp Number"
              />
              <InputText
                name="email"
                type="email"
                required
                value={state.email}
                onChange={onChange}
                placeholder="Email"
              />
              <InputText
                name="addressLine1"
                value={state.addressLine1}
                required
                onChange={onChange}
                placeholder="Address Line 1"
              />
              <InputText
                name="addressLine2"
                value={state.addressLine2}
                onChange={onChange}
                placeholder="Address Line 2"
              />
              <Button
                className="p-button-secondary"
                label="Get a Quatation"
                type="submit"
              ></Button>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const renderMyCart = () => {
    return (
      <div className="card flex flex-column mb-5">
        <h3 className="text-center">My Cart</h3>
        <div>
          {state.products.map((item, i) => (
            <React.Fragment key={i}>{renderListItem(item)}</React.Fragment>
          ))}
          <Button
            label="Clear Cart"
            className="p-button-secondary"
            type="button"
            onClick={clearCart}
            style={{ float: "right" }}
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <div style={{ marginBottom: "6rem" }}>
      <div className="content-wrapper">
        <h1 className="mt-3 text-center text-5xl line-height-3">Admin Cart</h1>
        <h5 className="mb-4 text-center">
          Get a quotation for the items in the cart
        </h5>
        <div className="grid">
          <div className="col-7">{renderMyCart()}</div>
          <div className="col-5">{renderSummery()}</div>
        </div>
      </div>
      <Dialog
        visible={dialog}
        style={{ width: "90vw", maxWidth: "300px", textAlign: "center" }}
        closable="false"
      >
        <ProgressSpinner style={{ width: "50px", height: "50px" }} />
        <h6>Please wait ...</h6>
      </Dialog>
    </div>
  );
};

export default AdminCart;
