export const item = {

    "Selection added to the cart successfully. Do you want to continue": {
        en:"Selection added to the cart successfully. Do you want to continue",
        si:"තේරීම සාර්ථකව කරත්තයට එක් කරන ලදී. ඔබට දිගටම කරගෙන යාමට අවශ්‍යද"
    },
    "shopping or go to the cart?": {
        en:"shopping or go to the cart?",
        si:"සාප්පු යාම හෝ කරත්තයට යන්නද?"
    },
    "Continue Shopping": {
        en:"Continue Shopping",
        si:"සාප්පු සවාරි දිගටම කරගෙන යන්න"
    },
    "Go to Cart": {
        en:"Go to Cart",
        si:"කරත්තයට යන්න"
    },
    "Customize Selected Design": {
        en:"Customize Selected Design",
        si:"තෝරාගත් නිර්මාණය අභිරුචිකරණය කරන්න"
    },
    "Type": {
        en:"Type",
        si:"වර්ගය"
    },
    "Frame Width": {
        en:"Frame Width",
        si:"රාමු පළල"
    },
    "Inches": {
        en:"Inches",
        si:"අඟල්"
    },
    "Height": {
        en:"Height",
        si:"උස"
    },
    "Number of Pleats": {
        en:"Number of Pleats",
        si:"ප්ලීට් ගණන"
    },
    "Quantity": {
        en:"Quantity",
        si:"ප්රමාණය"
    },
    "Price per unit": {
        en:"Price per unit",
        si:"ඒකකයකට මිල"
    },
    "Total": {
        en:"Total",
        si:"මුළු වටිනාකම"
    },
    "User Guide": {
        en:"User Guide",
        si:"පරිශීලක මාර්ගෝපදේශය"
    },
    "How to measure?": {
        en:"How to measure?",
        si:"මැනිය හැක්කේ කෙසේද?"
    },
    "All measurements should be in inches": {
        en:"All measurements should be in inches",
        si:"සියලුම මිනුම් අඟල් වලින් විය යුතුය"
    },
    "Width of the Window/Door outer frame": {
        en:"Width of the Window/Door outer frame",
        si:"කවුළුවේ/දොර පිටත රාමුවේ පළල"
    },
    "Height of the Window/Door outer frame": {
        en:"Height of the Window/Door outer frame",
        si:"ජනේල/දොර පිටත රාමුවේ උස"
    },
    "Measurement from the floor to the bottom of the window": {
        en:"Measurement from the floor to the bottom of the window",
        si:"බිම සිට කවුළුවේ පතුලේ සිට මැනීම"
    },
    "frame": {
        en:"frame",
        si:"රාමුව"
    },
    "Measurement from the ceiling/ roof/ slab to the top of": {
        en:"Measurement from the ceiling/ roof/ slab to the top of",
        si:"සිවිලිම / වහලය / ස්ලැබ් සිට ඉහළට මැනීම"
    },
    "the window frame": {
        en:"the window frame",
        si:"කවුළු රාමුව"
    },
    "Full height from the floor to the ceiling/ slab/ roof": {
        en:"Full height from the floor to the ceiling/ slab/ roof",
        si:"බිම සිට සිවිලිම / ස්ලැබ් / වහලය දක්වා සම්පූර්ණ උස"
    },
    "(Not essential)": {
        en:"(Not essential)",
        si:"(අත්‍යවශ්‍ය නොවේ)"
    },
    "Recommended": {
        en:"Recommended",
        si:"නිර්දේශ කර ඇත"
    },
    "Type & Width": {
        en:"Type & Width",
        si:"වර්ගය සහ පළල"
    },
    "The width of door & windows are predicted as below": {
        en:"The width of door & windows are predicted as below",
        si:"දොර ජනෙල්වල පළල පහත පරිදි පුරෝකථනය කර ඇත"
    },
    "Depending on the size of the door and window frame, add or": {
        en:"Depending on the size of the door and window frame, add or",
        si:"දොර සහ කවුළු රාමුවේ විශාලත්වය අනුව, එකතු කිරීම හෝ"
    },
    "subtract the value in the Width box.": {
        en:"subtract the value in the Width box.",
        si:"දොර සහ කවුළු රාමුවේ විශාලත්වය අනුව, එකතු කිරීම හෝ"
    },
    "Simply specify the inside width of your window frame and we will": {
        en:"Simply specify the inside width of your window frame and we will",
        si:"ඔබේ කවුළු රාමුවේ ඇතුළත පළල සරලව සඳහන් කරන්න, අපි කරන්නෙමු"
    },
    "increase it by 6 inches on each side.": {
        en:"increase it by 6 inches on each side.",
        si:"සෑම පැත්තකින්ම එය අඟල් 6 කින් වැඩි කරන්න."
    },
    "It is adequate to mention the necessary height in inches and the": {
        en:"It is adequate to mention the necessary height in inches and the",
        si:"අවශ්ය උස අඟල් වලින් සඳහන් කිරීම ප්රමාණවත්ය"
    },
    "cost differs according to three sizes of height.": {
        en:"cost differs according to three sizes of height.",
        si:"උස ප්රමාණ තුනක් අනුව පිරිවැය වෙනස් වේ."
    },
    "1. Less than 47 inches (this reduce a half of the cost of": {
        en:"1. Less than 47 inches (this reduce a half of the cost of",
        si:"1. අඟල් 47 ට අඩු (මෙය වියදමෙන් අඩක් අඩු කරයි"
    },
    "material in your design) Conditions Apply": {
        en:"material in your design) Conditions Apply",
        si:"ඔබේ නිර්මාණයේ ඇති ද්‍රව්‍ය) කොන්දේසි අදාළ වේ"
    },
    "2. From 48 inches up to 102 inches": {
        en:"2. From 48 inches up to 102 inches",
        si:"2. අඟල් 48 සිට අඟල් 102 දක්වා"
    },
    "3. From 103 inches to above": {
        en:"3. From 103 inches to above",
        si:"3. අඟල් 103 සිට ඉහළට"
    },
    "If 100 inches height is maintained a fantastic finish is": {
        en:"If 100 inches height is maintained a fantastic finish is",
        si:"අඟල් 100 ක උසකින් නඩත්තු කරන්නේ නම් විශිෂ්ට නිමාවකි"
    },
    "obtainable for your design": {
        en:"obtainable for your design",
        si:"ඔබගේ නිර්මාණය සඳහා ලබා ගත හැක"
    },
    "Pleats": {
        en:"Pleats",
        si:"ප්ලීට්"
    },
    "Usual practice is inserting 3 pleats per foot (The International": {
        en:"Usual practice is inserting 3 pleats per foot (The International",
        si:"සාමාන්‍ය පරිචය වන්නේ අඩියකට ප්ලීට් 3ක් ඇතුල් කිරීමයි (ද ඉන්ටර්නැෂනල්"
    },
    "Standards is 14 inch gap between two pleats). Accordingly, a": {
        en:"Standards is 14 inch gap between two pleats). Accordingly, a",
        si:"සම්මතයන් ප්ලීට් දෙකක් අතර අඟල් 14 ක පරතරයකි). ඒ අනුව, ඒ"
    },
    "quantity of material is been used,": {
        en:"quantity of material is been used,",
        si:"භාවිතා කරන ලද ද්රව්ය ප්රමාණය"
    },
    "The number of pleats can be decreased or increased according to": {
        en:"The number of pleats can be decreased or increased according to",
        si:"අනුව ප්ලීස් ගණන අඩු කිරීමට හෝ වැඩි කිරීමට හැකිය"
    },
    "your probability and requirement. The price depends with number": {
        en:"your probability and requirement. The price depends with number",
        si:"ඔබේ සම්භාවිතාව සහ අවශ්‍යතාවය. මිල අංකය මත රඳා පවතී"
    },
    "of pleats on your decision.": {
        en:"of pleats on your decision.",
        si:"ඔබේ තීරණය පිළිබඳ ආයාචනා."
    },
    "Notice": {
        en:"Notice",
        si:"දැනුම්දීම"
    },
    "If transparent curtains (sheer) are used for your window frames it": {
        en:"If transparent curtains (sheer) are used for your window frames it",
        si:"ඔබේ කවුළු රාමු සඳහා විනිවිද පෙනෙන තිර (පැහැදිලි) භාවිතා කරන්නේ නම්"
    },
    "is appropriate to increase a few number of pleats": {
        en:"is appropriate to increase a few number of pleats",
        si:"ප්ලීට් කිහිපයක් වැඩි කිරීමට සුදුසු වේ"
    },
    "Poles": {
        en:"Poles",
        si:"පොලු"
    },
    "For the purpose of curtain brackets of or own designs have been": {
        en:"For the purpose of curtain brackets of or own designs have been",
        si:"තිර වරහන් සඳහා හෝ ස්වකීය නිර්මාණ කර ඇත"
    },
    "utilized to date and it is made of standard galvanized tubes and": {
        en:"utilized to date and it is made of standard galvanized tubes and",
        si:"අද දක්වා භාවිතා කර ඇති අතර එය සම්මත ගැල්වනයිස් කරන ලද නල වලින් සාදා ඇත"
    },
    "quality wood. A long time warranty is granted and the benefits": {
        en:"quality wood. A long time warranty is granted and the benefits",
        si:"අද දක්වා භාවිතා කර ඇති අතර එය සම්මත ගැල්වනයිස් කරන ලද නල වලින් සාදා ඇත"
    },
    "you enjoy are,": {
        en:"you enjoy are,",
        si:"ඔබ භුක්ති විඳින්නේ,"
    },
    "As we charge only the production cost the prices prevail at a low": {
        en:"As we charge only the production cost the prices prevail at a low",
        si:"නිෂ්පාදන වියදම පමණක් අය කරන නිසා මිල අඩුයි"
    },
    "rate.": {
        en:"rate.",
        si:"අනුපාතය."
    },
    "As the bracket is provided with screws for installing facility and": {
        en:"As the bracket is provided with screws for installing facility and",
        si:"අපි නිෂ්පාදන පිරිවැය පමණක් අය කරන නිසා මිල අඩු මට්ටමක පවතිනවා"
    },
    "It is convenient to fix on the wall.": {
        en:"It is convenient to fix on the wall.",
        si:"බිත්තිය මත සවි කිරීම පහසුය."
    },
    "Depending on the location of walls and windows our brackets": {
        en:"Depending on the location of walls and windows our brackets",
        si:"බිත්ති සහ කවුළු අපගේ වරහන් පිහිටීම අනුව"
    },
    "consists of various shaped accessories.": {
        en:"consists of various shaped accessories.",
        si:"විවිධ හැඩැති උපාංග වලින් සමන්විත වේ."
    },
    "The fastener is furnished with a good quality wood (with no": {
        en:"The fastener is furnished with a good quality wood (with no",
        si:"ගාංචුව හොඳ තත්ත්වයේ ලී වලින් සපයා ඇත (අංක"
    },
    "adhesive) the security is high.": {
        en:"adhesive) the security is high.",
        si:"මැලියම්) ආරක්ෂාව ඉහළයි."
    },
    "It is long lasting": {
        en:"It is long lasting",
        si:"එය දිගු කල් පවතිනු ඇත"
    },
    "Attractive and available in many colours": {
        en:"Attractive and available in many colours",
        si:"ආකර්ශනීය සහ විවිධ වර්ණවලින් ලබාගත හැකිය"
    },
    "Being able to contribute to saving the environment as less wood is": {
        en:"Being able to contribute to saving the environment as less wood is",
        si:"දැව අඩුවෙන් පරිසරය සුරැකීමට දායක වීමට හැකි වීම"
    },
    "used.": {
        en:"used.",
        si:"භාවිතා කරන ලදී."
    },
    "As this is our own design this is not available in the open": {
        en:"As this is our own design this is not available in the open",
        si:"මේක අපේම නිර්මාණයක් නිසා මේක එළිමහනේ නැහැ"
    },    
    "market & accessories such as poles are not issued on your": {
        en:"market & accessories such as poles are not issued on your",
        si:"වෙළඳපොල සහ පොලු වැනි උපාංග ඔබේ මත නිකුත් නොකෙරේ"
    },
    "request.": {
        en:"request.",
        si:"ඉල්ලීම."
    },
    "You can select from here whether you need or not a pole": {
        en:"You can select from here whether you need or not a pole",
        si:"ඔබට කණුවක් අවශ්‍යද නැද්ද යන්න ඔබට මෙතැනින් තෝරා ගත හැක"
    },
    "Door": {
        en:"Door",
        si:"දොර"
    },
    "Single Window": {
        en:"Single Window",
        si:"තනි කවුළුව"
    },
    "Double Window": {
        en:"Double Window",
        si:"ද්විත්ව කවුළුව"
    },
    "Triple Window": {
        en:"Triple Window",
        si:"ත්රිත්ව කවුළුව"
    },
    "Quadruple Window": {
        en:"Quadruple Window",
        si:"චතුරස්රාකාර කවුළුව"
    },
    "Other": {
        en:"Other",
        si:"වෙනත්"
    },
    "View Description": {
        en:"View Description",
        si:"විස්තරය බලන්න"
    },
    "Without Pole": {
        en:"Without Pole",
        si:"පොල්ල නොමැතිව"
    },
    "With Pole": {
        en:"With Pole",
        si:"පොල්ල සමඟ"
    },
    "Add to Cart": {
        en:"Add to Cart",
        si:"ගැලට එක් කරන්න"
    }
    
};