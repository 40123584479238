/* eslint-disable no-undef */
import React from "react";
import MeetUs from "./home/meet-is";
import contactUsImage from "../assets/img/contact-us/background.jpg";

const ContactUs = () => {
  return (
    <div>
      <div
        className="bg-left-top flex flex-column align-items-center lg:pt-7 pt-5 contact-us-header"
        style={{ backgroundImage: `url(${contactUsImage})` }}
      >
        <h1 className="text-primary cormorant-garamond font-bold text-10xl">
          Get in
        </h1>
        <h1 className="text-primary cormorant-garamond font-bold text-10xl lg:mt-5 mt-2 text-touch">
          Touch
        </h1>
        <h1 className="text-secondary cormorant-garamond lg:text-8xl text-6xl text-center lg:mt-3">
          Today
        </h1>
        <div className="flex justify-content-center lg:gap-5 gap-2 cormorant-garamond lg:text-4xl text-lg lg:mt-6 mt-3 text-color mb-6">
          <div className="flex flex-column gap-2 font-light contact-info-list">
            <div className="flex align-items-center">
              <i className="icon-border pi pi-phone mr-2"></i>Hotline
            </div>
            <div className="flex align-items-center">
              <i className="icon-border pi pi-envelope mr-2"></i>Email
            </div>
            <div className="flex align-items-center">
              <i className="icon-border pi pi-facebook mr-2"></i>Facebook
            </div>
            <div className="flex align-items-center">
              <i className="icon-border pi pi-youtube mr-2"></i>YouTube
            </div>
          </div>
          <div className="flex flex-column gap-2 font-medium">
            <div>+94 71 802 1976</div>
            <div>geethcurtains@gmail.com</div>
            <div>www.facebook.com/geethcurtain</div>
            <div>www.youtube.com/@geethcurtain</div>
          </div>
        </div>
      </div>
      <div className="lg:mt-6 mt-3">
        <MeetUs>Meet Us</MeetUs>
      </div>
    </div>
  );
};

export default ContactUs;