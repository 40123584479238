import React, { Component } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { InputSwitch } from "primereact/inputswitch";
import { numberWithCommas } from "../CustomMath";
import "assets/css/item.css";

const defaultState = {
  type: "Door",
  pleatFlag: false,
  fabricPrice: 0,
  price: 850,
  width: 36,
  height: 100,
  pleats: 9,
  cost: 0,
  quantity: 1,
  total: 0,
  isPole: true,
  fabricCost: 0,
};

const types = [
  { name: "Door", value: 36 },
  { name: "Single Window", value: 24 },
  { name: "Double Window", value: 48 },
  { name: "Triple Window", value: 72 },
  { name: "Quadruple Window", value: 96 },
  { name: "Other", value: 120 },
];

export default class Calculator extends Component {
  constructor(props) {
    super(props);
    this.state = { ...defaultState };
  }

  componentDidMount() {
    this.calculate(this.state);
  }

  onTypeChange = (value) => {
    const type = types.find((t) => t.name === value);
    if (!type) return;

    this.setState({ type: type.name, width: type.value }, () =>
      this.calculate(this.state, true)
    );
  };

  onPoleChange = (e) => {
    this.setState({ isPole: e.value }, () => this.calculate(this.state, false));
  };

  onChange = (name, value) => {
    this.setState({ [name]: value }, () =>
      this.calculate(this.state, name === "width")
    );
  };

  calculate = (currentState = this.state, getPleats = true) => {
    let pleats = getPleats
      ? Math.round(currentState.width / 3.92)
      : currentState.pleats;
    if (
      pleats % 2 !== 0 &&
      !(currentState.type === "Door" || currentState.type === "Single Window" || currentState.type === "Double Window" || currentState.type === "Triple Window" || currentState.type === "Quadruple Window" || currentState.type === "Other")
    ) {
      pleats += 1;
    }

    let fabricPrice = currentState.price;
    if (currentState.height > 102) fabricPrice += currentState.price * 0.1;
    if (currentState.height < 48) fabricPrice = currentState.price / 2;

    const barCost =
      (currentState.width < 48 ? 5 : currentState.width / 12 + 1) * 300;
    let cost =
      (barCost +
        ((pleats * 14) / 39) * (fabricPrice + 65) +
        pleats * 60 +
        pleats * 2 * 46) *
      1.26702;
    if (!currentState.isPole) cost -= barCost * 1.25;

    const total = cost * currentState.quantity;

    this.setState({
      ...currentState,
      pleats,
      fabricPrice,
      cost,
      total,
    });
  };

  reset = () => {
    const resetState = { ...defaultState };
    this.setState(resetState, () => this.calculate(resetState));
  };

  render() {
    return (
      <div className="content-wrapper">
        <div
          className="flex flex-column gap-3 p-4"
          style={{
            minHeight: "calc(100vh - 419px)",
            display: "flex",
            paddingBottom: "20px",
          }}
        >
          <h4>Create Quick Quotation</h4>

          <div className="flex flex-column gap-2">
            <div className="flex flex-column">
              <label>Price</label>
              <InputNumber
                className="max-w-full lg:w-7rem"
                min={0}
                value={this.state.price}
                onChange={(e) => this.onChange("price", e.value)}
                showButtons
                buttonLayout="horizontal"
                step={50}
                decrementButtonClassName="p-button-primary"
                incrementButtonClassName="p-button-primary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
            </div>

            <div className="flex flex-column">
              <label>Type</label>
              <SelectButton
                value={this.state.type}
                options={types}
                onChange={(e) => this.onTypeChange(e.value)}
                optionLabel="name"
                optionValue="name"
                className="w-full"
              />
            </div>

            <div className="flex flex-column">
              <label>Width</label>
              <InputNumber
                className="max-w-full lg:w-7rem"
                min={0}
                max={500}
                value={this.state.width}
                onChange={(e) => this.onChange("width", e.value)}
                showButtons
                buttonLayout="horizontal"
                step={1}
                decrementButtonClassName="p-button-primary"
                incrementButtonClassName="p-button-primary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
            </div>

            <div className="flex flex-column">
              <label>Height</label>
              <InputNumber
                className="max-w-full lg:w-7rem"
                min={0}
                max={120}
                value={this.state.height}
                onChange={(e) => this.onChange("height", e.value)}
                showButtons
                buttonLayout="horizontal"
                step={1}
                decrementButtonClassName="p-button-primary"
                incrementButtonClassName="p-button-primary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
            </div>

            <div className="flex flex-column">
              <label>Number of Pleats</label>
              <InputNumber
                className="max-w-full lg:w-7rem"
                min={1}
                value={this.state.pleats}
                onChange={(e) => this.onChange("pleats", e.value)}
                showButtons
                buttonLayout="horizontal"
                step={1}
                decrementButtonClassName="p-button-primary"
                incrementButtonClassName="p-button-primary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
            </div>

            <div className="flex flex-column">
              <label>With Pole</label>
              <InputSwitch
                checked={this.state.isPole}
                onChange={this.onPoleChange}
              />
            </div>

            <div className="flex flex-column">
              <label>Quantity</label>
              <InputNumber
                className="w-full lg:w-7rem"
                min={1}
                value={this.state.quantity}
                onChange={(e) => this.onChange("quantity", e.value)}
                showButtons
                step={1}
                buttonLayout="horizontal"
                decrementButtonClassName="p-button-primary"
                incrementButtonClassName="p-button-primary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
            </div>

            <div className="flex flex-column align-items-start mt-2">
              <strong>
                Price per unit: LKR {numberWithCommas(this.state.cost)}
              </strong>
              <strong>Total: LKR {numberWithCommas(this.state.total)}</strong>
            </div>

            <Button
              className="mt-4 p-button-outlined btn-outlined"
              style={{ width: "150px" }}
              label="Reset"
              icon="pi pi-refresh"
              onClick={this.reset}
            />
          </div>
        </div>
      </div>
    );
  }
}
