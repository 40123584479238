export const meetUS = {

  "Meet Us": { en: "Meet Us", si: "අප හා හමුවන්න" },
  "Hotline": { en: "Hotline", si: "හොට්ලයින්" },
  "Get Directions": { en: "Get Directions", si: "දැක්ම ලබාගන්න" },
  "With our strategically located branches spread across the island we are here to ensure that our service is easily accessible to you wherever you are.":
    {
      en: "With our strategically located branches spread across the island we are here to ensure that our service is easily accessible to you wherever you are.",
      si: "අපේ සේවාව ඔබට පහසුවෙන් ලැබෙන ආකාරයට විදුලිය පතුරවන ගෘහවල පිහිටි අපේ ශාඛා සමග.",
    },
  "Ragama": { en: "Ragama", si: "රාගම" },
  "Walpola": { en: "Walpola", si: "වල්පොල" },
  "Welimada": { en: "Welimada", si: "වෙලිමඩ" },
  "Kurunegala": { en: "Kurunegala", si: "කුරුණෑගල" },
  "No 72, Thewatha Road, Ragama": {
    en: "No 72, Thewatha Road, Ragama",
    si: "අංක 72, තේවත්ත පාර, රාගම",
  },
  "No 16, Walpola Ragama": { en: "No 16, Walpola Ragama", si: "අංක 16, වල්පොල රාගම" },
  "9th Post Nawela, Mirahawaththa, Welimada": {
    en: "9th Post Nawela, Mirahawaththa, Welimada",
    si: "9 වැනි තැපෑල නාවෙලා, මිරහවත්ත, වෙලිමඩ",
  },
  "Danbokka Junction, Colombo Rd, Kurunegala": {
    en: "Danbokka Junction, Colombo Rd, Kurunegala",
    si: "දන්බොක්කා හන්දිය, කොළඹ පාර, කුරුණෑගල",
  },
  
};