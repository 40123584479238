import React, { useEffect, useRef, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { FileUpload } from "primereact/fileupload";
import { FilterMatchMode } from "primereact/api";
import { Toast } from "primereact/toast";

const defaultFormState = {
  code: "",
  name: "",
  price: "",
  order: Date.now(),
  imageFile: null,
  image: null,
};

const Items = () => {
  const [products, setProducts] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formValues, setFormValues] = useState({ ...defaultFormState });
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const confirmingDelete = useRef(false);
  const toast = useRef(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const data = [];
    const querySnapshot = await getDocs(collection(db, "Designs"));
    querySnapshot.forEach((doc) => {
      if (doc.exists && doc.data().Status) {
        const record = {
          id: doc.id,
          code: doc.data().Code,
          name: doc.data().Name,
          image: doc.data().Image,
          price: doc.data().Price,
          order: doc.data().Order,
        };
        data.push(record);
      }
    });
    setProducts(data);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormValues((prevValues) => ({
        ...prevValues,
        image: file.name,
        imageFile: file,
      }));
    };
    reader.readAsDataURL(file);
  };

  const openAddDialog = () => {
    clearForm();
    setIsEditing(false);
    setShowDialog(true);
  };

  const uploadImageAndGetURL = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    return new Promise((resolve, reject) => {
      uploadTask.on("state_changed", null, reject, async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        resolve(downloadURL);
      });
    });
  };

  const addOrUpdateProduct = async () => {
    if (
      !formValues.name ||
      !formValues.code ||
      !formValues.price ||
      !formValues.order ||
      !formValues.image
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "All fields are required",
        life: 3000,
      });
      return;
    }

    if (formValues.imageFile) {
      await uploadImageAndGetURL(formValues.imageFile);
    }

    const productData = {
      Code: formValues.code,
      Name: formValues.name,
      Price: parseFloat(formValues.price),
      Order: parseInt(formValues.order),
      Image: formValues.image,
      Status: true,
    };

    if (isEditing && selectedProduct) {
      await deleteDoc(doc(db, "Designs", selectedProduct.id));
    }

    await addDoc(collection(db, "Designs"), productData);

    loadData();
    setShowDialog(false);
  };

  const editProduct = (product) => {
    setIsEditing(true);
    setSelectedProduct(product);
    setFormValues({
      code: product.code,
      name: product.name,
      price: product.price,
      order: product.order,
      imageFile: null,
      image: product.image,
    });
    setShowDialog(true);
  };

  const deleteProduct = async (product) => {
    const storage = getStorage();
    const storageRef = ref(storage, `images/${product.image}`);
    try {
      await deleteObject(storageRef);
    } catch (error) {
      console.error("Error deleting image:", error);
    }
    await deleteDoc(doc(db, "Designs", product.id));
    loadData();
  };

  const confirmDeleteProduct = (product) => {
    confirmDialog({
      message: "Are you sure you want to delete this item?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => deleteProduct(product),
      reject: () => (confirmingDelete.current = false),
    });
  };

  const clearForm = () => {
    setFormValues({ ...defaultFormState, order: Date.now() });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({ ...filters, global: { ...filters.global, value } });
  };

  const renderDialogContent = () => (
    <div className="flex gap-4" style={{ width: "600px" }}>
      <div
        className="flex flex-column align-items-center"
        style={{ width: "250px" }}
      >
        <label>Current Image</label>
        {formValues.imageFile ? (
          <img
            src={URL.createObjectURL(formValues.imageFile)}
            alt="Selected"
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        ) : formValues.image ? (
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/geethcuratins.appspot.com/o/${formValues.image}?alt=media&token=debc5e8a-2c21-45a3-b0d6-50de7f8166d7`}
            alt="Selected"
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "300px",
              backgroundColor: "#e0e0e0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <span>No Image</span>
          </div>
        )}
      </div>

      <div className="flex flex-column gap-2" style={{ width: "400px" }}>
        <div className="flex flex-column">
          <label>Item Name:</label>
          <InputText
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            className="p-inputtext-sm"
            required
          />
        </div>
        <div className="flex flex-column">
          <label>Item Code:</label>
          <InputText
            name="code"
            value={formValues.code}
            onChange={handleInputChange}
            className="p-inputtext-sm"
            required
          />
        </div>
        <div className="flex flex-column">
          <label>Price Per Meter:</label>
          <InputNumber
            name="price"
            value={formValues.price}
            onValueChange={(e) =>
              setFormValues((prev) => ({ ...prev, price: e.value }))
            }
            mode="currency"
            currency="LKR"
            locale="en-US"
            className="p-inputtext-sm"
            required
          />
        </div>
        <div className="flex flex-column">
          <label>Order:</label>
          <InputNumber
            name="order"
            value={formValues.order}
            onValueChange={(e) =>
              setFormValues((prev) => ({ ...prev, order: e.value }))
            }
            className="p-inputtext-sm"
            required
          />
        </div>
        <div className="flex flex-column">
          <label>Upload Image:</label>
          <FileUpload
            mode="basic"
            accept="image/*"
            customUpload
            uploadHandler={handleFileChange}
            auto
            chooseLabel="Select File"
            className="p-inputtext-sm"
            required
          />
        </div>
        <div className="flex gap-2 mt-4">
          <Button
            label="Save"
            onClick={addOrUpdateProduct}
            className="p-button-outlined btn-outlined"
          />
          <Button
            label="Clear"
            onClick={clearForm}
            className="p-button-outlined btn-outlined"
          />
        </div>
      </div>
    </div>
  );

  const imageBodyTemplate = (rowData) => (
    <img
      src={`https://firebasestorage.googleapis.com/v0/b/geethcuratins.appspot.com/o/${rowData.image}?alt=media&token=debc5e8a-2c21-45a3-b0d6-50de7f8166d7`}
      alt={rowData.name}
      style={{ width: "50px", height: "50px", objectFit: "cover" }}
    />
  );

  return (
    <div
      className="flex flex-column gap-3 p-5"
      style={{ minHeight: "calc(100vh - 419px)" }}
    >
      <Toast ref={toast} />
      <div className="flex gap-2 justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm"
            type="search"
            onInput={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <div className="flex gap-2">
          <Button
            label="Refresh"
            icon="pi pi-refresh"
            onClick={loadData}
            className="p-button-outlined p-button-sm btn-outlined"
          />
          <Button
            label="Add New Item"
            onClick={openAddDialog}
            className="p-button-outlined p-button-sm btn-outlined"
          />
        </div>
      </div>
      <DataTable
        value={products}
        paginator
        rows={20}
        rowsPerPageOptions={[20, 50, 100]}
        filters={filters}
        globalFilterFields={["name", "code", "price"]}
        size="small"
      >
        <Column field="image" header="Image" body={imageBodyTemplate} />
        <Column sortable field="order" header="Order" />
        <Column sortable field="code" header="Code" />
        <Column sortable field="name" header="Name" />
        <Column
          sortable
          field="price"
          header="Price"
          body={(rowData) => `${rowData.price.toFixed(2)} LKR`}
          style={{ textAlign: "right" }}
        />
        <Column
          body={(rowData) => (
            <div className="flex gap-2">
              <Button
                label="Edit"
                icon="pi pi-pencil"
                onClick={() => editProduct(rowData)}
                className="p-button-text"
              />
              <Button
                label="Delete"
                icon="pi pi-trash"
                onClick={() => confirmDeleteProduct(rowData)}
                className="p-button-text p-button-danger"
              />
            </div>
          )}
          header="Actions"
        />
      </DataTable>
      <Dialog
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        draggable={false}
        modal={false}
        header={isEditing ? "Edit Item" : "Add New Item"}
      >
        {renderDialogContent()}
      </Dialog>
    </div>
  );
};

export default Items;
